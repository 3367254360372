<template>
  <div class="pt-12">
    <a
      href="#"
      @click.stop.prevent="dialog=true"
    >
      {{ $t('onboarding.investmentSurvey.components.skip.trigger') }}
    </a>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      width="700"
    >
      <v-card>
        <v-card-title>
          {{ $t('onboarding.investmentSurvey.components.skip.title') }}
        </v-card-title>

        <v-divider></v-divider>

        <v-container>
          <v-checkbox
            v-model="data.investmentSurvey.skipTest"
            :label="$t('onboarding.investmentSurvey.components.skip.confirmation')"
            color="primary"
          ></v-checkbox>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            {{ $t('onboarding.investmentSurvey.components.skip.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="agree"
            :disabled="!data.investmentSurvey.skipTest"
          >
            {{ $t('onboarding.investmentSurvey.components.skip.next') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import clone from '@/utils/clone';

export default {
  name: 'SkipInvestmentSurvey',
  data() {
    return {
      data: clone(this.$store.state.user.data),
      dialog: false,
    };
  },
  methods: {
    ...mapActions('onboarding', [
      'updateUser',
      'completed',
      'setSection',
    ]),
    close() {
      this.dialog = false;
    },
    async agree() {
      try {
        await this.updateUser(this.data);
      } catch (error) {
        this.$notify.error(error.message);
      }
      this.completed(true);
      this.setSection('videoIdent');
      this.$router.push({
        name: 'Onboarding.Overview',
      });
      this.close();
    },
  },
};
</script>
